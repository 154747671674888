import React, { useState } from "react";
import Home from "components/_Landing/Home";
import SEO from "components/Seo";

const AboutPage = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return (
    <>
      <Home />;
    </>
  );
};

export default AboutPage;

export const Head = () => (
  <SEO
    title={
      "About Bimeco | The World's Leading BIM Service Provider since 1996. We provide BIM Modelling, BIM Object Creation, and BIM Consulting Services."
    }
  />
);
